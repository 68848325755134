import React, { Suspense, lazy } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import ProtectedRoute from './components/routing/ProtectedRoute';
import { useSelector } from 'react-redux';
import RecentDesigns from './components/routing/RecentDesigns';
import ProjectHistory from './components/routing/ProjectHistory';
import ProjectHistoryOverview from './components/routing/ProjectHistoryOverview';

const LogIn = lazy(() => {
  return import('./components/routing/Login');
});
const NoPage = lazy(() => import('./components/error_pages/NoPage'));
const PhotoShop = lazy(() => import('./components/PhotoShop'));

export default function App() {
  const loggedInUser = useSelector((state) => state.session.loggedIn);
  return (
    <BrowserRouter>
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route element={<ProtectedRoute user={loggedInUser} />}>
            <Route path="projects/:projectId" element={<PhotoShop />} />
            <Route path="history/:projectId" element={<ProjectHistory />} />
            <Route path="history/:projectId/:historyId" element={<ProjectHistoryOverview />} />
          </Route>
          <Route index element={loggedInUser ? <RecentDesigns /> : <LogIn />} />
          <Route path="*" element={<NoPage />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}
