import React from 'react';
import { useSelector } from 'react-redux';
import { selectLayout } from '../store/layouts';

export default function Thumbnail(props) {
  const { project } = props;
  const [banner] = project.banners.filter((banner) => banner.enabled);

  const layout = useSelector((state) => selectLayout(state, project.layoutId));

  const getTransform = (data, translateTopLeft) => {
    let r = `scale(${String(data.scale || 1)})`;
    if (data.rotate) {
      r += ` rotate(${data.rotate}deg)`;
    }
    if (translateTopLeft) {
      r += ` translate(-50%, -50%)`;
    }
    return r;
  };

  return project?.thumbnail && project?.thumbnail?.length ? <img style={{ width: '100%' }} alt={project?.name} src={project.thumbnail} /> : (
    <div
      className="thumbnail"
      style={{
        //overflow: selectedBanner === banner.id || bannerHover === banner.id ? 'visible' : 'hidden',
        position: 'relative',
        boxSizing: 'border-box',
        background: banner.bgColor,
        display: 'inline-block',
        zoom: banner.width > 600 ? 0.1 : 0.2,
        width: banner.width,
        height: banner.height,
        overflow: 'hidden',
      }}
    >
      <div
        style={{
          position: 'relative',
          top: 0,
          left: 0,
          zoom: 1,
          height: '100%',
        }}
      >
        {layout && Object.keys(layout?.settings?.media).map(
          (k, idx) =>
            banner[k] && (
              <img
                key={`${k}_${project.id}`}
                alt="foo"
                style={{
                  position: 'absolute',
                  width: banner[k].width,
                  height: banner[k].height,
                  top: banner[k].y,
                  left: banner[k].x,
                  transform: getTransform(banner[k], false),
                  zIndex: banner[k].zIndex || idx + 1,
                }}
                src={
                  banner[k]?.src ||
                  project?.template[`${k.replace('_', '')}SrcAll`] ||
                  layout?.template[`${k.replace('_', '')}SrcAll`]
                } // Urgent: Fix the keys
              />
            ),
        )}

        {layout && ['cta', ...Object.keys(layout?.settings?.text)].map(
          (k, idx) =>
            banner[k] && (
              <span
                key={`${k}_${project.id}`}
                style={{
                  fontFamily: banner[k].fontFamily,
                  fontSize: banner[k].fontSize,
                  position: 'absolute',
                  top: `${banner[k].offsetYPercent}%`,
                  left: `${banner[k].offsetXPercent}%`,
                  transform: getTransform(banner[k], true),
                  zIndex: banner[k].zIndex || idx + 1,
                }}
              >
                {banner[k].text}
              </span>
            ),
        )}
      </div>
    </div>
  );
}
