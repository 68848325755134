import { createEntityAdapter, createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import client from '../utils/client';

const settingsAdapter = createEntityAdapter({
  selectId(entity) {
    return entity.searchId;
  },
});

const initialState = settingsAdapter.getInitialState({
  loading: 'idle' | 'pending' | 'succeeded' | 'failed',
  lastError: null,
});

export const execFetchSettings = createAsyncThunk('settings/all', async (payload, { getState }) => {
  const token = getState()?.session.token;

  const res = await client.request({
    url: '/settings',
    method: 'GET',
    headers: { Authorization: `Bearer ${token}` },
  });
  return res.data;
});

const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    /// RECENT PROJECTS
    builder
      .addCase(execFetchSettings.pending, (state, action) => {
        state.loading = 'pending';
      })
      .addCase(execFetchSettings.fulfilled, (state, action) => {
        state.loading = 'succeeded';
        settingsAdapter.setAll(state, action.payload.docs);
      })
      .addCase(execFetchSettings.rejected, (state, action) => {
        state.loading = 'failed';
        state.lastError = action.payload;
      });
  },
});

export default settingsSlice.reducer;

export const { selectAll: selectSettings, selectById: selectSetting } = settingsAdapter.getSelectors(
  (state) => state?.settings ?? initialState,
);
