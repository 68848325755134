import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import client from '../utils/client';

const initialState = {
  loggedIn: false,
  user: Object(),
  token: null,
  lastError: null,
};

export const performLogin = createAsyncThunk('session/login', async (payload, {  rejectWithValue }) => {
  try {
    const res = await client.request({ url: '/auth/login', method: 'POST', data: { ...payload } });
    return res.data;
  } catch (e) {
    return rejectWithValue(e.response.data);
  }
});

export const performLogout = createAsyncThunk('session/logout', async (_, { getState }) => {
  const token = getState().session?.token;
  const res = await client.request({
    url: '/auth/logout',
    method: 'POST',
    headers: { Authorization: `Bearer ${token}` },
  });
  return res.data;
});

const sessionSlice = createSlice({
  name: 'session',
  initialState,
  extraReducers: (builder) => {
    /// LOGIN
    builder
      .addCase(performLogin.fulfilled, (state, action) => {
        state.user = action.payload.user;
        state.token = action.payload.token;
        state.loggedIn = true;
      })
      .addCase(performLogin.rejected, (state, action) => {
        state.loggedIn = false;
        state.user = Object();
        state.token = null;
        state.lastError = action.payload;
      });

    /// LOGOUT

    builder
      .addCase(performLogout.fulfilled, (state, action) => {
        state.loggedIn = false;
        state.user = Object();
        state.token = null;
      })
      .addCase(performLogout.rejected, (state, action) => {
        state.loggedIn = false;
        state.user = Object();
        state.token = null;
        state.lastError = action.payload;
      });
  },
  reducers: {},
});

export default sessionSlice.reducer;
