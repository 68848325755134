import React, { forwardRef, useCallback, useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { TextField } from '@mui/material';

const ConfirmDialog = forwardRef(({ open, onCancel, onConfirm, data }, ref) => {
  const [note, setNote] = useState('');

  return (
    <Dialog open={open} aria-labelledby="note-dialog-title" aria-describedby="note-dialog-form" maxWidth="800px">
      <DialogTitle id="note-dialog-title">Leave a note</DialogTitle>
      <DialogContent>
        <TextField
          id="standard-multiline-static"
          label="Multiline"
          multiline
          rows={7}
          value={note}
          onChange={(e) => setNote(e.target.value)}
          variant="standard"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>Omit</Button>
        <Button disabled={!note || (note && !note.length)} onClick={(e) => onConfirm(data, note)} autoFocus>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
});

export default ConfirmDialog;
