import React from 'react';
import './Overlay.scss';
import { useSelector } from 'react-redux';
import { Backdrop, CircularProgress, LinearProgress } from '@mui/material';

function Overlay() {
  const open = useSelector((state) => state.global.overlay?.visible);
  const loader = useSelector((state) => state.global.loader);
  const message = useSelector((state) => state.global.overlay?.message);

  return (
    <Backdrop sx={{ color: '#fff', zIndex: 120, flexDirection: 'column' }} className="overlay" open={open}>
      {loader && loader.visible ? (
        loader.circular ? (
          <CircularProgress color="inherit" size={loader?.size || 60} />
        ) : (
          <LinearProgress color="inherit" />
        )
      ) : (
        <></>
      )}
      {message && message.length && (
        <>
          <div style={{ display: 'block', textTransform: 'uppercase' }}>
            <h2 dangerouslySetInnerHTML={{ __html: message }}></h2>
          </div>
        </>
      )}
    </Backdrop>
  );
}

export default Overlay;
