import React, { useEffect, useRef, useState } from 'react';
import { FormControl, IconButton, Input, InputAdornment } from '@mui/material';
import { GrClose, GrEdit } from 'react-icons/gr';
import { styled } from '@mui/material/styles';
import './TableCellNameInput.scss';

const StyledInput = styled(Input)(({ theme }) => ({
  color: 'black',
  background: 'rgba(255,255,255,0.8)',
  paddingTop: 5,
  paddingBottom: 5,
  paddingLeft: 10,
  paddingRight: 10,
  border: '1px solid #444',
  '& .Mui-disabled': {
    background: 'transparent !important',
    color: 'rgba(0,0,0,0.9) !important',
    WebkitTextFillColor: 'rgba(0,0,0,0.9) !important',
  },
}));

function TableCellNameInput({ id, name, handleUpdate, handleEditState }) {
  const inputRef = useRef();
  const [changedName, setChangedName] = useState(undefined);
  const [editMode, setEditMode] = useState(false);

  useEffect(() => {
    setChangedName(name);
  }, [name]);

  const handleUpdateOnBlur = async (e) => {
    e.preventDefault();
    if (changedName !== name) {
      const res = await handleUpdate({ id, value: changedName });
      if (!res || (res && !res.success)) {
        setChangedName(res.value);
      }
    }
    setEditMode(false);
    return setTimeout(() => handleEditState(false), 500);
  };

  const onHandleEditState = () => {
    handleEditState(true);
    setEditMode(true);
  };

  return (
    <div
      className="table-name-input"
      onClick={(e) => {
        if (!editMode) {
          return onHandleEditState();
        }
      }}
    >
      {editMode ? (
        <FormControl sx={{ m: 0, width: '100%', zIndex: 1000 }} variant="standard">
          <StyledInput
            id="standard-adornment-password"
            disableUnderline
            inputRef={inputRef}
            defaultValue={changedName}
            autoFocus
            disabled={!editMode}
            onBlur={handleUpdateOnBlur}
            onChange={(e) => setChangedName(e.target.value)}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  size="small"
                  aria-label="toggle input disabled"
                  onClick={(e) => {
                    if (editMode) {
                      return handleUpdateOnBlur(e);
                    } else {
                      return setEditMode(true);
                    }
                  }}
                >
                  {editMode ? <GrClose /> : <GrEdit />}
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
      ) : (
        <div
          className="table-name-placeholder"
          style={{
            width: '100%',
            fontSize: '1rem',
            padding: 10,
            lineHeight: '1.4375em',
          }}
        >
          {changedName}
          <span>
            <GrEdit />
          </span>
        </div>
      )}
    </div>
  );
}

export default TableCellNameInput;
