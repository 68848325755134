import { createEntityAdapter, createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import client from '../utils/client';

const layoutAdapter = createEntityAdapter({
  sortComparer: (a, b) => {
    return a.order - b.order;
  },
});

const initialState = layoutAdapter.getInitialState({
  loading: 'idle' | 'pending' | 'succeeded' | 'failed',
  lastError: null,
});

export const execFetchLayouts = createAsyncThunk('layouts/all', async (payload, { getState }) => {
  const token = getState()?.session.token;

  const res = await client.request({
    url: '/layouts',
    method: 'GET',
    headers: { Authorization: `Bearer ${token}` },
  });
  return res.data;
});

const layoutSlice = createSlice({
  name: 'layouts',
  initialState,
  reducers: {
    clearState() {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    /// RECENT PROJECTS
    builder
      .addCase(execFetchLayouts.pending, (state, action) => {
        state.loading = 'pending';
      })
      .addCase(execFetchLayouts.fulfilled, (state, action) => {
        state.loading = 'succeeded';
        layoutAdapter.setAll(state, action.payload.docs);
      });
  },
});

export default layoutSlice.reducer;

export const { clearState: clearLayoutState } = layoutSlice.actions;

export const {
  selectAll: selectLayouts,
  selectById: selectLayout,
  selectTotal: selectLayoutCount,
} = layoutAdapter.getSelectors((state) => state?.layouts ?? initialState);

export const selectDefaultLayout = (state) => {
  const layout = selectLayouts(state).find((layout) => layout.defaultLayout);
  if (layout?.length) {
    return layout[0];
  }
  const layouts = selectLayouts(state);
  if (!layouts.length) {
    return {};
  }
  return layouts[0];
};
