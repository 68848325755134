import React, { useCallback, useEffect, useRef, useState } from 'react';
import './ProjectHistory.scss';
import { GrDownload, GrFolderOpen, GrLinkPrevious } from 'react-icons/gr';
import TopBar from '../TopBar';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { selectProject } from '../../store/projects';
import { DateTime } from 'luxon';
import { DataGrid } from '@mui/x-data-grid';
import { Alert, Button, IconButton, Snackbar } from '@mui/material';
import { styled } from '@mui/material/styles';

import { hideOverlay } from '../../store/global';
import Modal from '../Modal';
import Overlay from '../Overlay';
import { FiEdit, FiLink } from 'react-icons/fi';

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  border: 0,
  fontSize: '12px',
  fontFamily: '"Macys Sans Regular", sans-serif',
  '& .MuiDataGrid-columnHeaders, .MuiDataGrid-row': {
    borderBottom: '2px dotted #707070',
  },
  '& .MuiDataGrid-columnHeaderTitle': {
    fontFamily: '"Macys Sans Bold", sans-serif',
    fontSize: '14px',
  },
  '& .MuiDataGrid-cell': {
    border: 'none',
    paddingTop: '10px',
    paddingBottom: '10px',
    cursor: 'pointer',
    '&[data-field="cta"]': {
      cursor: 'unset',
    },
    '&:focus-within': {
      outline: 'none',
    },
  },
  '& .MuiDataGrid-columnSeparator': {
    opacity: '0 !important',
  },
  '& .MuiDataGrid-columnHeader:focus, .MuiDataGrid-cell:focus': {
    outline: 'none',
  },
}));

function ProjectHistory() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { projectId } = useParams();
  const project = useSelector((state) => selectProject(state, projectId));

  const session = useSelector((state) => state.session);
  const [snackbar, setSnackbar] = useState(() => ({
    open: false,
    message: null,
    autoHideDuration: 5000,
    severity: 'info',
  }));
  const modal = useSelector((state) => state.global.modal);

  const ctrl = new AbortController();
  useEffect(() => {
    if (!ctrl.signal.aborted) {
      dispatch(hideOverlay());
    }
    return () => ctrl.abort();
  }, [dispatch]);

  const handleDownload = (link, e) => {
    e.preventDefault();
    const el = document.createElement('a');
    el.href = link;
    document.body.appendChild(el);
    el.click();
    el.remove();
  };

  const handlePreview = (link, e) => {
    window.open(link, '_blank', 'noopener, noreferrer');
  };

  const handleClickOnRow = (data, e) => {
    if (data?.field === 'cta') {
      return true;
    }
    if (!data.row.gcpData?.banners) return false;

    let hasNewDetail = false;
    for (const banner of data.row.gcpData?.banners) {
      if (Array.isArray(banner)) {
        break;
      }

      if (banner.size) {
        hasNewDetail = true;
      }
    }

    if (!hasNewDetail) return false;

    e.preventDefault();
    return navigate(`/history/${project.id}/${data.row.id}`);
  };

  const columns = [
    {
      field: 'version',
      headerName: 'Version',
      disableColumnMenu: true,
      sortable: false,
      align: 'right',
      headerAlign: 'right',
      width: 80,
      renderCell: (params) => {
        return <strong>{params.row.version}</strong>;
      },
    },
    {
      headerName: 'Created',
      field: 'createdAt',
      width: 200,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <span
            style={{
              fontFamily: '"Macys Sans Bold", sans-serif',
            }}
          >
            {params.row?.createdAt
              ? DateTime.fromISO(params.row.createdAt).toLocaleString(DateTime.DATETIME_SHORT)
              : 'N/A'}
          </span>
        );
      },
    },
    {
      headerName: 'Creator',
      field: 'user',
      sortable: false,
      width: 150,
      disableColumnMenu: true,
      renderCell: (params) => {
        return params.row?.user?.firstName
          ? `${params.row?.user?.firstName} ${params.row?.user?.lastName}`
          : params.row?.user?.name || `${session.user.firstName} ${session.user.lastName}`;
      },
    },
    {
      headerName: 'Note',
      field: 'note',
      flex: 1,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <span
            style={{
              fontFamily: '"Macys Sans Medium", sans-serif',
            }}
          >
            {params.row.note || '--'}
          </span>
        );
      },
    },
    {
      headerName: '',
      field: 'cta',
      sortable: false,
      filterable: false,
      resizeable: false,
      disableColumnMenu: true,
      width: 140,
      renderCell: (params) => {
        return (
          <div
            id={params?.row.uuid}
            style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', flex: 1, fontSize: '1rem' }}
          >
            <IconButton size="small" aria-label="preview" onClick={(e) => handlePreview(params.row.gcpData.preview, e)}>
              <GrFolderOpen title="Open preview" />
            </IconButton>
            <IconButton size="small" aria-label="download" onClick={(e) => handleDownload(params.row.gcpData.zip, e)}>
              <GrDownload title="Download Zip" />
            </IconButton>
          </div>
        );
      },
    },
  ];

  return (
    <div className="projectHistory">
      {modal.visible ? <Modal /> : null}
      <TopBar recentDesign={true} />
      <Overlay />
      <div id="main-container">
        <div
          style={{
            padding: '40px 10px 25px 10px',
            display: 'flex',
            alignItems: 'center',
            boxSizing: 'border-box',
            justifyContent: 'space-between',
          }}
        >
          <IconButton size="small" onClick={(e) => navigate('/')} style={{ marginRight: 10 }}>
            <GrLinkPrevious />
          </IconButton>
          <h2
            style={{
              fontFamily: '"Macys Sans Regular", sans-serif',
              fontSize: '16px',
              margin: 0,
              flex: 1,
              textTransform: 'uppercase',
              color: '#707070',
            }}
          >
            PROJECT: <span style={{ color: 'black' }}>{project.name}</span>{' '}
            <span style={{ color: '#707070' }}> | Export History</span>
          </h2>
          <a style={{ cursor: 'pointer' }} onClick={(e) => navigate(`/projects/${project.id}`)}></a>
          <Button
            size="small"
            aria-label="preview"
            type="button"
            sx={{ color: '#000000', fontFamily: '"Macys Sans Medium", sans-serif' }}
            startIcon={<FiEdit title="Edit project" />}
            onClick={(e) => navigate(`/projects/${project.id}`)}
          >
            Edit
          </Button>
        </div>

        <StyledDataGrid
          initialState={{
            sorting: {
              sortModel: [{ field: 'version', sort: 'desc' }],
            },
          }}
          disableMultipleColumnsSorting
          disableColumnSelector
          disableSelectionOnClick
          disableColumnFilter
          rows={project?.projectHistory || []}
          rowHeight={87}
          columns={columns}
          onCellClick={handleClickOnRow}
          rowsPerPageOptions={[5, 10, 20, 100]}
        />
        <Snackbar
          anchorOrigin={snackbar?.anchor}
          open={snackbar?.open}
          autoHideDuration={snackbar?.autoHideDuration}
          onClose={() => setSnackbar({ open: false, message: null, severity: 'info' })}
        >
          <Alert severity={snackbar?.severity}>{snackbar?.message}</Alert>
        </Snackbar>
      </div>
    </div>
  );
}

export default ProjectHistory;
