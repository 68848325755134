import React, { useCallback, useEffect, useRef, useState } from 'react';
import './ProjectHistory.scss';
import { GrHtml5, GrLinkPrevious } from 'react-icons/gr';
import TopBar from '../TopBar';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { selectProject } from '../../store/projects';
import { DataGrid } from '@mui/x-data-grid';
import { Alert, IconButton, Snackbar, Button, Typography, Popover, Chip } from '@mui/material';
import { styled } from '@mui/material/styles';
import { hideOverlay } from '../../store/global';
import Modal from '../Modal';
import Overlay from '../Overlay';
import { getBannerLabel } from '../../utils';
import { FiEdit, FiLink } from 'react-icons/fi';
import { BsFillEnvelopeFill } from 'react-icons/bs';

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  border: 0,
  fontSize: '12px',
  fontFamily: '"Macys Sans Regular", sans-serif',
  '& .MuiDataGrid-columnHeaders, .MuiDataGrid-row': {
    borderBottom: '2px dotted #707070',
  },
  '& .MuiDataGrid-columnHeaderTitle': {
    fontFamily: '"Macys Sans Bold", sans-serif',
    fontSize: '14px',
  },
  '& .MuiDataGrid-cell': {
    border: 'none',
    marginTop: '10x',
    marginBottom: '10px',
    paddingTop: '20px',
    paddingBottom: '20px',
    paddingLeft: '10px',
    paddingRight: '10px',
    cursor: 'pointer',
    '&[data-field="cta"]': {
      cursor: 'unset',
    },
    '&:focus-within': {
      outline: 'none',
    },
  },
  '& .MuiDataGrid-columnSeparator': {
    opacity: '0 !important',
  },
  '& .MuiDataGrid-columnHeader:focus, .MuiDataGrid-cell:focus': {
    outline: 'none',
  },
}));

function ProjectHistoryOverview() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { projectId, historyId } = useParams();
  const project = useSelector((state) => selectProject(state, projectId));
  const doc = project ? project.projectHistory.find((row) => row.id === historyId) : {};

  const [messageOpen, setMessageOpen] = useState(null);

  const [snackbar, setSnackbar] = useState(() => ({
    open: false,
    message: null,
    autoHideDuration: 5000,
    severity: 'info',
  }));
  const modal = useSelector((state) => state.global.modal);

  const ctrl = new AbortController();
  useEffect(() => {
    if (!ctrl.signal.aborted) {
      dispatch(hideOverlay());
    }
    return () => ctrl.abort();
  }, [dispatch]);

  const handleDownload = async (link, e) => {
    e.preventDefault();
    const el = document.createElement('a');
    el.href = link;
    el.target = '_blank';
    el.download = link.split('/').pop();
    document.body.appendChild(el);
    el.click();
    el.remove();
  };

  const handleImageDownload = async (link, e) => {
    e.preventDefault();
    const el = document.createElement('a');
    el.href = link;
    el.target = '_blank';
    el.download = '';
    document.body.appendChild(el);
    el.click();
    el.remove();
  };

  const downloadImage = (link) => {
    const img = new Image();
    const c = document.createElement('canvas');
    const ctx = c.getContext('2d');
    return new Promise((resolve, reject) => {
      img.onload = function () {
        c.width = this.naturalWidth; // update canvas size to match image
        c.height = this.naturalHeight;
        console.log(this);
        ctx.drawImage(this, 0, 0); // draw in image
        debugger;
        c.toBlob(
          function (blob) {
            // get content as JPEG blob
            // here the image is a blob
            console.log(blob);
            return resolve(blob);
          },
          'image/jpeg',
          1,
        );
      };
      img.src = link;
    });
  };

  const handlePreview = (link, e) => {
    window.open(link, '_blank', 'noopener, noreferrer');
  };

  const handleClickOnRow = (data, e) => {
    if (data?.field === 'cta' || data?.field === 'html' || data?.field === 'image') {
      return true;
    }

    e.preventDefault();
    console.log(data);
    return handlePreview(data.row.image);
  };

  const columns = [
    {
      field: 'index',
      headerName: 'Index',
      disableColumnMenu: true,
      sortable: false,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        return params.api.getRowIndexRelativeToVisibleRows(params.rowNode.id) + 1;
      },
    },
    {
      field: 'width',
      headerName: 'Width',
      disableColumnMenu: true,
      sortable: false,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        return <span>{params.row.size[0]}</span>;
      },
    },
    {
      field: 'height',
      headerName: 'Height',
      disableColumnMenu: true,
      sortable: false,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        return <span>{params.row.size[1]}</span>;
      },
    },
    {
      field: 'fileSize',
      headerName: 'FileSize',
      disableColumnMenu: true,
      sortable: false,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        return <span>{params.row.fileSize ? String(Math.round(params.row.fileSize)).concat('KB') : 'N/A'}</span>;
      },
    },
    {
      headerName: 'Image',
      field: 'image',
      flex: 1,
      sortable: false,
      align: 'left',
      headerAlign: 'center',
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <div style={{ textAlign: 'center' }} onClick={(e) => handleImageDownload(params.row.image, e)}>
            <img
              alt={`banner_image_${params.row.size[0]}x${params.row.size[1]}`}
              src={params.row.image}
              style={{ width: 'auto', maxWidth: '100%', height: 'auto', maxHeight: 80 }}
            />
            <small style={{ display: 'block', width: '100%' }}>
              {params.row?.label || getBannerLabel({ width: params.row.size[0], height: params.row.size[1] }, 1)}
            </small>
          </div>
        );
      },
    },
    {
      headerName: 'Html',
      field: 'html',
      sortable: false,
      disableColumnMenu: true,
      headerAlign: 'center',
      renderCell: (params) => {
        return (
          <div>
            <Button
              size="small"
              aria-label="preview"
              type="button"
              sx={{ color: '#000000' }}
              startIcon={<FiLink title="Open Html Banner" />}
              onClick={(e) => handleDownload(params.row.html, e)}
            >
              OPEN
            </Button>
          </div>
        );
      },
    },
  ];

  return (
    <div className="projectHistory">
      {modal.visible ? <Modal /> : null}
      <TopBar recentDesign={true} />
      <Overlay />
      <div id="main-container">
        <div
          style={{
            padding: '40px 10px 25px 10px',
            display: 'flex',
            alignItems: 'center',
            boxSizing: 'border-box',
            justifyContent: 'space-between',
          }}
        >
          <IconButton size="small" onClick={(e) => navigate(`/history/${project.id}`)} style={{ marginRight: 10 }}>
            <GrLinkPrevious />
          </IconButton>
          <h2
            style={{
              fontFamily: '"Macys Sans Regular", sans-serif',
              fontSize: '16px',
              margin: 0,
              flex: 1,
              textTransform: 'uppercase',
              color: '#707070',
            }}
          >
            PROJECT: <span style={{ color: 'black' }}>{project.name}</span>{' '}
            <span style={{ color: '#707070' }}>
              {' '}
              | Exported Content |{' '}
              <strong style={{ color: 'black', fontWeight: 'bold', textTransform: 'lowercase' }}>
                v{doc.version}
              </strong>{' '}
            </span>
          </h2>
          <div style={{ marginRight: 20 }}>
            {doc.note && (
              <div className="note">
                <Chip
                  label="Note"
                  style={{ paddingLeft: 10, paddingRight: 5 }}
                  icon={<BsFillEnvelopeFill />}
                  variant="filled"
                  color="primary"
                  onClick={(e) => setMessageOpen(e.currentTarget)}
                />
                <Popover
                  id={`${project.name}_${doc.version}`}
                  anchorEl={messageOpen}
                  open={Boolean(messageOpen)}
                  onClose={() => setMessageOpen(null)}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                >
                  <Typography sx={{ p: 2 }}>{doc.note}</Typography>
                </Popover>
              </div>
            )}
          </div>
          <Button
            size="small"
            aria-label="preview"
            type="button"
            sx={{ color: '#000000', fontFamily: '"Macys Sans Medium", sans-serif' }}
            startIcon={<FiEdit title="Edit project" />}
            onClick={(e) => navigate(`/projects/${project.id}`)}
          >
            Edit
          </Button>
        </div>
        <StyledDataGrid
          initialState={{
            sorting: {
              sortModel: [{ field: 'version', sort: 'desc' }],
            },
          }}
          disableMultipleColumnsSorting
          disableColumnSelector
          disableSelectionOnClick
          disableColumnFilter
          rows={doc?.gcpData?.banners || []}
          rowHeight={110}
          columns={columns}
          onCellClick={handleClickOnRow}
          getRowId={(params) => {
            return `${params.size[0]}_${params.size[1]}_${Math.random() * 100}`;
          }}
        />
        <Snackbar
          anchorOrigin={snackbar?.anchor}
          open={snackbar?.open}
          autoHideDuration={snackbar?.autoHideDuration}
          onClose={() => setSnackbar({ open: false, message: null, severity: 'info' })}
        >
          <Alert severity={snackbar?.severity}>{snackbar?.message}</Alert>
        </Snackbar>
      </div>
    </div>
  );
}

export default ProjectHistoryOverview;
