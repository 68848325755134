import React, { useEffect, useRef, useState } from 'react';
import './TopBar.scss';
import vcgtLogo from '../assets/logos/main_logo.svg';
import previewWhiteIcon from '../assets/icons/preview-white.svg';
import downloadIcon from '../assets/icons/download-icon.svg';
import leftCaret from '../assets/icons/left-caret.svg';
import { useDispatch, useSelector } from 'react-redux';
import UserMenu from './UserMenu';
import { useNavigate, useParams, useMatch, matchPath } from 'react-router';
import { useLocation } from 'react-router-dom';
import {
  createProject,
  execCreateBanners,
  execValidateProjectName,
  selectProject,
  updateProject,
} from '../store/projects';
import { selectDefaultLayout, selectLayoutCount, selectLayouts } from '../store/layouts';
import { DateTime } from 'luxon';
import { hideOverlay, showOverlay, showSnackbar, sideMenuSelect, zoomCanvas } from '../store/global';
import { v4 as uuidV4 } from 'uuid';
import { CircularProgress, Icon, IconButton } from '@mui/material';
import { IoArrowBack, IoArrowForward, IoCheckmarkCircle, IoDownload, IoSend } from 'react-icons/io5';
import { ActionCreators } from 'redux-undo';
import { GrDownload } from 'react-icons/gr';
import { FiDownload, FiSend } from 'react-icons/fi';
import NoteDialog from './dialogs/NoteDialog';

function TopBar(props) {
  const ref = useRef();
  const { pathname } = useLocation();
  const m = matchPath('/projects/:id', pathname);

  const [noteDialogOpen, setNoteDialogOpen] = useState(false);
  const [noteDialogData, setNoteDialogData] = useState(() => {
    return { type: null };
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [projectName, setProjectName] = useState(props.project?.name || '');
  const project = m?.params.id ? useSelector((state) => selectProject(state, m.params.id)) : null;
  const saveState = useSelector((state) => state.projects?.present?.saveState);
  const pastLength = useSelector((state) => state?.projects?.past?.length);
  const futureLength = useSelector((state) => state?.projects?.future?.length);
  const experimentalFeatures = useSelector((state) => state.global.experimentalFeatures);
  const layoutCount = useSelector(selectLayoutCount);
  const c = new AbortController();
  const defaultLayout = useSelector(selectDefaultLayout);
  const permissionLevel = useSelector((state) => state?.session?.user?.role);

  useEffect(() => {
    if (!c.signal.aborted) {
      if (projectName !== props.project?.name && props.project?.name) {
        setProjectName(props.project.name);
      }
    }
    return () => c.abort();
  }, [props.project?.name]);

  const hitEnter = (event) => {
    if (event.key === 'Enter' || event.key === 'Escape') {
      ref.current.blur();
    }
  };

  const handleCreateDesign = () => {
    const template = { ...JSON.parse(JSON.stringify(defaultLayout?.template)) };
    const banners = [...JSON.parse(JSON.stringify(defaultLayout?.banners))];

    dispatch(zoomCanvas(0.6));
    dispatch(sideMenuSelect(0));

    const data = {
      id: uuidV4(),
      layoutId: defaultLayout.id,
      draft: true,
      template,
      banners,
      saved: false,
      createdAt: DateTime.utc().toISO(),
      updatedAt: DateTime.utc().toISO(),
    };
    dispatch(createProject(data));

    return navigate(`/projects/${data.id}`);
  };

  const handleOnBlurProjectName = async () => {
    if (props.project?.name === projectName || !projectName) return false;
    try {
      const res = await dispatch(
        execValidateProjectName({ keyword: projectName, projectId: props.project?.id }),
      ).unwrap();
      if (res.valid) {
        dispatch(updateProject({ id: props.project?.id, value: { name: projectName } }));
      } else {
        dispatch(showSnackbar({ message: 'Duplicated name, please choose a unique project name', severity: 'error' }));
        setProjectName(props.project?.name);
      }
    } catch (e) {
      setProjectName(props.project.name);
    }
  };

  const execDownload = (link) => {
    const el = document.createElement('a');
    el.href = link;
    document.body.appendChild(el);
    el.click();
    el.remove();
  };

  const onSaveNoteDialog = async (type, note) => {
    dispatch(showOverlay({ message: 'Creating banner media', loader: { visible: true } }));
    try {
      const links = await dispatch(execCreateBanners({ id: props.project?.id, note })).unwrap();
      if (type === 'banners') {
        return execDownload(links.zip);
      }
      window.open(links.preview, '_blank', 'noopener, noreferrer');
    } catch (e) {
      dispatch(showSnackbar({ severity: 'error', message: e.message }));
    } finally {
      dispatch(hideOverlay());
      setNoteDialogData({ type: null });
    }
  };

  const handleOnClickDownload = (type) => {
    if (project && project?.projectHistory?.length) {
      const lastUpdate = project?.updatedAt;
      const historyLast = [...project.projectHistory].pop();
      const { projectModified, note = null } = historyLast;

      if (lastUpdate === projectModified) {
        return onSaveNoteDialog(type, note);
      }
    }
    setNoteDialogData({ type });
    setNoteDialogOpen(true);
  };

  const handleNoteDialogConfirm = (res, note) => {
    setNoteDialogOpen(false);
    return onSaveNoteDialog(res.type, note);
  };

  const handleNoteDialogCancel = () => {
    setNoteDialogOpen(false);
    const { type } = noteDialogData;
    return onSaveNoteDialog(type);
  };

  return (
    <div id="top-bar">
      <div id="top-bar-left-group">
        <div id="top-bar-logo-container">
          <img src={vcgtLogo} alt="vcgt-logo" />
        </div>
        {m && (
          <>
            <hr />
            <div id="top-bar-project-name-container">
              <button type="button" id="home-button" onClick={() => navigate('/')}>
                <img src={leftCaret} alt="<" />
                <span>Home</span>
              </button>
              <h3>Project Name:</h3>
              <input
                ref={ref}
                type="text"
                min="0"
                max="36"
                value={String(projectName)}
                placeholder={'Project name'}
                onKeyDown={hitEnter}
                onChange={(event) => setProjectName(event.target.value)}
                onBlur={handleOnBlurProjectName}
              />
              <div id="status-icon">
                {saveState === 'pending' && (
                  <CircularProgress
                    size={16}
                    thickness={4}
                    sx={{
                      color: 'orange',
                      zIndex: 1,
                    }}
                  />
                )}

                {saveState === 'fulfilled' && <IoCheckmarkCircle color="grey" size={18} />}

                <span
                  style={{
                    fontFamily: 'MACYS SANS BOLD',
                    textTransform: 'uppercase',
                    fontSize: '12px',
                    marginLeft: '3px',
                    color: 'var(--top-bar-fg-color)',
                  }}
                >
                  {saveState === 'pending' ? 'Saving' : saveState === 'fulfilled' ? 'Saved' : ''}
                </span>

                {experimentalFeatures && (
                  <>
                    <IconButton
                      aria-label="history-back"
                      disabled={!pastLength}
                      onClick={() => dispatch(ActionCreators.undo())}
                    >
                      <IoArrowBack />
                    </IconButton>
                    <IconButton
                      aria-label="history-back"
                      disabled={!futureLength}
                      onClick={() => dispatch(ActionCreators.redo())}
                    >
                      <IoArrowForward />
                    </IconButton>
                  </>
                )}
              </div>
            </div>
          </>
        )}
      </div>

      <div id="top-bar-right-group">
        <div id="top-bar-buttons-container">
          {!props.project ? (
            <button
              id="create-a-design"
              type="button"
              disabled={!layoutCount}
              aria-label="create-design"
              title={(!layoutCount && 'No layouts assigned to the current account') || 'Create a new layout'}
              onClick={handleCreateDesign}
            >
              <span>Create a Design</span>
            </button>
          ) : (
            <>
              <button
                disabled={props.project?.draft || saveState !== 'idle'}
                id="download-images-btn"
                type="button"
                onClick={() => handleOnClickDownload('banners')}
              >
                <FiDownload
                  strokeWidth={2}
                  style={{ color: saveState !== 'idle' ? '#707070' : '#707070' }}
                ></FiDownload>
                <span>Download</span>
              </button>
              <button
                disabled={props.project?.draft || saveState !== 'idle'}
                id="share-review"
                type="button"
                onClick={() => handleOnClickDownload()}
              >
                <FiSend
                  strokeWidth={2}
                  style={{ color: saveState !== 'idle' || props.project?.draft ? '#707070' : 'white' }}
                ></FiSend>
                <span>Share Link to Preview</span>
              </button>
            </>
          )}

          <UserMenu />
        </div>
      </div>
      <NoteDialog
        open={noteDialogOpen}
        data={noteDialogData}
        onCancel={handleNoteDialogCancel}
        onConfirm={handleNoteDialogConfirm}
      />
    </div>
  );
}

export default TopBar;
