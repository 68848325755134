import React, { useCallback, useEffect, useRef, useState } from 'react';
import './RecentDesigns.scss';
import { GrClone, GrList, GrTrash } from 'react-icons/gr';
import TopBar from '../TopBar';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import {
  clearActiveProject,
  deleteDraft,
  execCloneProject,
  execDeleteProject,
  execGetRecentProjects,
  execValidateProjectName,
  filterTable,
  getPagination,
  getTableFilter,
  getTableOrder,
  selectProjects,
  setActiveProject,
  updateOrder,
  updatePagination,
  updateProject,
} from '../../store/projects';
import { execFetchLayouts } from '../../store/layouts';
import { DateTime } from 'luxon';
import { DataGrid, GridToolbarQuickFilter } from '@mui/x-data-grid';
import DeleteDialog from '../dialogs/DeleteDialog';
import { Alert, IconButton, Snackbar, Badge } from '@mui/material';
import { styled } from '@mui/material/styles';

import Thumbnail from '../Thumbnail';
import { hideOverlay, hideSnackbar, showModal, showSnackbar } from '../../store/global';
import Modal from '../Modal';
import Overlay from '../Overlay';
import { execFetchSettings } from '../../store/settings';
import TableCellNameInput from '../TableCellNameInput';
import QuickFilter from '../QuickFilter';

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  border: 0,
  fontSize: '12px',
  fontFamily: '"Macys Sans Regular", sans-serif',
  '& .MuiDataGrid-columnHeaders, .MuiDataGrid-row': {
    borderBottom: '2px dotted #707070',
  },
  '& .MuiDataGrid-columnHeaderTitle': {
    fontFamily: '"Macys Sans Bold", sans-serif',
    fontSize: '14px',
  },
  '& .MuiDataGrid-cell': {
    border: 'none',
    paddingTop: '10px',
    paddingBottom: '10px',
    cursor: 'pointer',
    '&[data-field="cta"]': {
      cursor: 'unset',
    },
    '&:focus-within': {
      outline: 'none',
    },
  },
  '& .MuiDataGrid-columnSeparator': {
    opacity: '0 !important',
  },
  '& .MuiDataGrid-columnHeader:focus, .MuiDataGrid-cell:focus': {
    outline: 'none',
  },
}));

function RecentDesigns() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const projects = useSelector(selectProjects);
  const filter = useSelector(getTableFilter);

  const ref = useRef();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogData, setDialogData] = useState(null);
  const [editMode, setEditMode] = useState(undefined);

  const session = useSelector((state) => state.session);
  const pagination = useSelector(getPagination);
  const tableOrder = useSelector(getTableOrder);
  const [snackbar, setSnackbar] = useState(() => ({
    open: false,
    message: null,
    autoHideDuration: 5000,
    severity: 'info',
  }));
  const modal = useSelector((state) => state.global.modal);

  const ctrl = new AbortController();
  useEffect(() => {
    if (!ctrl.signal.aborted) {
      dispatch(clearActiveProject());
      dispatch(hideOverlay());
      dispatch(execFetchSettings());
      dispatch(execFetchLayouts());
      dispatch(execGetRecentProjects());
    }
    return () => ctrl.abort();
  }, [dispatch]);

  const handleProjectDelete = (e, data) => {
    e.preventDefault();
    if (data.draft) {
      return dispatch(deleteDraft(data.id));
    }

    dispatch(showModal({ mode: 3, context: { projectId: data.id } }));
  };

  const handleDialogCancel = () => {
    setDialogOpen(false);
    setDialogData(null);
  };

  const handleDialogConfirm = (data) => {
    setDialogOpen(false);
    setDialogData(null);
    dispatch(execDeleteProject(data.id));
  };

  const handleNameChange = async (newRow, oldRow) => {
    if (newRow.name === oldRow.name) return oldRow;

    try {
      dispatch(updateProject({ id: oldRow.id, value: { name: newRow.name } }));
    } catch (e) {
      setSnackbar({ autoHideDuration: 5000, message: e.message, severity: 'error', open: true });
      return oldRow;
    }

    return newRow;
  };

  const handlePageChange = (page) => {
    dispatch(updatePagination({ page }));
  };

  const handlePageSizeChange = (take) => {
    dispatch(updatePagination({ take }));
  };

  const handleRowUpdateError = useCallback((error) => {
    alert(error.message);
  }, []);

  const handleTableSort = (d) => {
    if (editMode) return false;

    const [first] = d;
    dispatch(updateOrder(first));
  };

  const onBlurInputName = async (data, currentName) => {
    let ret;
    try {
      const { payload } = await dispatch(execValidateProjectName({ keyword: data.value, projectId: data.id }));
      if (payload.valid) {
        await dispatch(updateProject({ id: data.id, value: { name: data.value } }));
        setSnackbar({
          autoHideDuration: 5000,
          message: 'Project name updated successfully',
          severity: 'success',
          open: true,
        });

        ret = { success: true, value: null };
      } else {
        setSnackbar({
          autoHideDuration: 5000,
          message: 'Duplicated name, please choose a unique project name',
          severity: 'error',
          open: true,
        });
        ret = { success: false, value: currentName };
      }
    } catch (e) {
      dispatch(showSnackbar({ message: e.message, severity: 'error' }));
      ret = { success: false, value: currentName };
    }
    return ret;
  };

  const handleClickOnRow = (data, e) => {
    if (editMode) return false;

    if (
      data?.field === 'cta' ||
      (data.field === 'name' &&
        (e.target.nodeName === 'INPUT' || e.target.nodeName === 'path' || e.target.nodeName === 'svg'))
    ) {
      return true;
    }

    if (
      (e.target && e.target.classList.contains('table-name-input')) ||
      (e.target && e.target.classList.contains('table-name-placeholder'))
    ) {
      return true;
    }

    if (e.target && e.target.classList && e.target.classList.contains('MuiInput-root')) {
      return true;
    }

    e.preventDefault();
    dispatch(setActiveProject(data?.id));
    return navigate(`/projects/${data?.id}`);
  };

  const handleTableFilter = (e) => {
    const { quickFilterValues } = e;
    const [value] = quickFilterValues;
    dispatch(filterTable(value));
  };

  const columns = [
    {
      field: 'thumbnail',
      headerName: 'Project name',
      width: 120,
      sortable: false,
      filterable: false,
      resizeable: false,
      draggable: false,
      disableColumnMenu: true,
      align: 'center',
      sx: {
        padding: 0,
      },
      renderCell: (params) => {
        return <Thumbnail project={params.row} />;
      },
    },
    {
      field: 'name',
      headerName: '',
      flex: 2,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <TableCellNameInput
            name={params.row.name}
            id={params.row.id}
            handleEditState={(v) => {
              setEditMode(v);
            }}
            handleUpdate={(res) => onBlurInputName(res, params.row.name)}
          />
        );
      },
    },
    {
      headerName: 'Template',
      field: 'template',
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        return <span id={params.row.template?.id}>{params.row.template?.description?.title?.replace(':', '')}</span>;
      },
    },
    {
      headerName: 'Edited',
      field: 'updatedAt',
      flex: 1,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <span
            style={{
              fontFamily: '"Macys Sans Bold", sans-serif',
            }}
          >
            {params.row?.updatedAt
              ? DateTime.fromISO(params.row.updatedAt).toLocaleString(DateTime.DATETIME_SHORT)
              : 'N/A'}
          </span>
        );
      },
    },
    {
      headerName: 'Creator',
      field: 'creator',
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        return params.row?.creator?.firstName
          ? `${params.row?.creator?.firstName} ${params.row?.creator?.lastName}`
          : params.row?.creator?.name || `${session.user.firstName} ${session.user.lastName}`;
      },
    },
    {
      headerName: '',
      field: 'cta',
      sortable: false,
      filterable: false,
      resizeable: false,
      disableColumnMenu: true,
      width: 140,
      renderCell: (params) => {
        return (
          <div
            id={params?.row.uuid}
            style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', flex: 1, fontSize: '1rem' }}
          >
            <IconButton
              size="small"
              color={params.row.projectHistory?.length === 0 ? 'primary' : 'secondary'}
              aria-label="copy"
              onClick={(e) => navigate(`/history/${params.row?.id}`)}
              disabled={params.row.projectHistory?.length === 0}
            >
              <Badge
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                badgeContent={params.row.projectHistory?.length}
                color="warning"
              >
                <GrList size={20} />
              </Badge>
            </IconButton>
            <IconButton size="small" aria-label="copy" onClick={(e) => dispatch(execCloneProject(params.row.id))}>
              <GrClone title="Make a copy" />
            </IconButton>
            <IconButton size="small" aria-label="delete" onClick={(e) => handleProjectDelete(e, params.row)}>
              <GrTrash title="Delete project" />
            </IconButton>
          </div>
        );
      },
    },
  ];

  return (
    <div id="recent-design-background">
      {modal.visible ? <Modal /> : null}
      <TopBar recentDesign={true} />
      <Overlay />
      <div id="main-container">
        <StyledDataGrid
          experimentalFeatures={{ newEditingApi: true }}
          disableMultipleColumnsSorting
          disableColumnSelector
          disableSelectionOnClick
          disableColumnFilter
          disableDensitySelector
          rows={projects}
          rowHeight={87}
          rowCount={pagination?.totalCount}
          columns={columns}
          page={pagination?.page}
          pageSize={pagination?.take}
          sortModel={[tableOrder]}
          filterMode="server"
          paginationMode="server"
          sortingMode="server"
          rowsPerPageOptions={[5, 10, 20]}
          processRowUpdate={handleNameChange}
          onProcessRowUpdateError={handleRowUpdateError}
          onPageChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          onSortModelChange={handleTableSort}
          onCellClick={handleClickOnRow}
          components={{ Toolbar: QuickFilter }}
          componentProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: {
                debounceMs: 500,
              },
            },
          }}
          initialState={{
            filter: {
              filterModel: {
                items: [],
                quickFilterValues: [filter],
              },
            },
          }}
          onFilterModelChange={handleTableFilter}
        />
        <DeleteDialog
          open={dialogOpen}
          data={dialogData}
          onCancel={handleDialogCancel}
          onConfirm={handleDialogConfirm}
        />
        <Snackbar
          anchorOrigin={snackbar?.anchor}
          open={snackbar?.open}
          autoHideDuration={snackbar?.autoHideDuration}
          onClose={() => setSnackbar({ open: false, message: null, severity: 'info' })}
        >
          <Alert severity={snackbar?.severity}>{snackbar?.message}</Alert>
        </Snackbar>
      </div>
    </div>
  );
}

export default RecentDesigns;
