import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  lastError: null,
  showSidePanel: true,
  sideMenuSelection: 1,
  canvasZoom: 0.6,
  darkMode: false,
  overlay: {
    visible: false,
    message: null,
    hasLoader: false,
  },
  loader: {
    visible: false,
    circular: true,
    bar: false,
    value: null,
  },
  modal: {
    visible: false,
    mode: null,
    context: null,
  },
  confirmDialog: {
    open: false,
    text: null,
    label: null,
  },
  distractionFreeMode: false,
  ctaList: [
    'Shop Now',
    'Shop Deals',
    'Shop the Sale',
    "Today's Deals",
    'Shop The Collection',
    'Shop The Look',
    'Shop Trend',
    'Check it Out',
    'Shop New Arrivals',
    'Buy the look',
  ],
  snackbar: {
    message: null,
    open: false,
    severity: 'info',
    autoHideDuration: 5000,
    anchor: {
      vertical: 'top',
      horizontal: 'center',
    },
  },
  experimentalFeatures: false,
};

const globalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    toggleExperimentalFeatures(state) {
      state.experimentalFeatures = !state.experimentalFeatures;
    },
    toggleDarkMode(state) {
      state.darkMode = !state.darkMode;
    },
    zoomCanvas(state, action) {
      state.canvasZoom = action.payload;
    },
    zoomIn(state) {
      state.canvasZoom = state.canvasZoom < 3 ? (state.canvasZoom += 0.01) : 3;
    },
    zoomOut(state) {
      state.canvasZoom = state.canvasZoom > 0.25 ? (state.canvasZoom -= 0.01) : 0.25;
    },
    toggleSidePanel(state) {
      state.showSidePanel = !state.showSidePanel;
    },
    sideMenuSelect(state, action) {
      state.sideMenuSelection = Number(action.payload);
    },
    showModal(state, action) {
      state.modal.visible = true;
      state.modal.mode = action.payload?.mode || action.payload;
      if (action.payload?.context) {
        state.modal.context = action.payload.context;
      }
    },
    hideModal(state, action) {
      state.modal.visible = false;
      state.modal.mode = null;
      state.modal.context = null;
    },
    showOverlay(state, action) {
      state.overlay.visible = true;
      if (action.payload) {
        state.overlay.message = action.payload?.message || action.payload;
        if (action.payload.loader && typeof action.payload.loader === 'object') {
          state.loader = { ...initialState?.loader, ...state.loader, ...action.payload.loader };
          state.loader.visible = true;
          state.overlay.hasLoader = true;
        } else if (action.payload.loader && typeof action.payload.loader !== 'object') {
          state.loader.visible = true;
          state.overlay.hasLoader = true;
        }
      }
    },
    toggleDistractionFreeMode(state, action) {
      state.distractionFreeMode = !state.distractionFreeMode;
    },
    hideOverlay(state) {
      state.overlay.visible = false;
      state.overlay.message = initialState.overlay.message;
      if (state.overlay.hasLoader) {
        state.overlay.hasLoader = false;
        state.loader.visible = false;
      }
    },
    showSnackbar(state, action) {
      state.snackbar = {
        ...state.snackbar,
        ...action.payload,
        anchor: { ...initialState.snackbar.anchor, ...(action.payload.anchor || {}) },
        open: true,
      };
    },
    hideSnackbar(state) {
      state.snackbar = { ...initialState.snackbar };
    },
    setConfirmDialog(state, action) {
      state.confirmDialog = action.payload || initialState.confirmDialog;
    },
    toggleOverlay: {
      reducer(state, action) {
        const { visible, message } = action.payload;
        state.overlay.visible = typeof visible !== 'undefined' ? visible : !state.overlay.visible;
        if (message) {
          state.overlay.message = message;
        }
      },
      prepare(visible = undefined, message = undefined) {
        return {
          payload: {
            visible,
            message,
          },
        };
      },
    },
  },
});

export const {
  zoomCanvas,
  zoomIn,
  zoomOut,
  toggleSidePanel,
  sideMenuSelect,
  toggleOverlay,
  showModal,
  hideModal,
  showOverlay,
  hideOverlay,
  toggleExperimentalFeatures,
  showSnackbar,
  hideSnackbar,
  setConfirmDialog,
} = globalSlice.actions;

export default globalSlice.reducer;
