import { DateTime } from 'luxon';
import { createAsyncThunk } from '@reduxjs/toolkit';
import client from './client';

export const layoutBannerKeyMapper = {
  bgImage: {
    x: 'bgImageXPositionAll',
    y: 'bgImageYPositionAll',
    zIndex: 'bgZIndexAll',
    offsetXPercent: 'bgImageXPositionAll',
    offsetYPercent: 'bgImageYPositionAll',
    scale: 'bgImageScaleAll',
    rotate: 'bgImageRotateAll',
    src: 'bgImageSrcAll',
  },
  logo: {
    x: 'logoXPositionAll',
    y: 'logoYPositionAll',
    zIndex: 'logoZIndexAll',
    offsetXPercent: 'logoXPositionAll',
    offsetYPercent: 'logoYPositionAll',
    scale: 'logoScaleAll',
    rotate: 'logoRotateAll',
    src: 'logoSrcAll',
  },
  image_1: {
    x: 'image1XPositionAll',
    y: 'image1YPositionAll',
    zIndex: 'image1ZIndexAll',
    offsetXPercent: 'image1XPositionAll',
    offsetYPercent: 'image1YPositionAll',
    scale: 'image1ScaleAll',
    rotate: 'image1RotateAll',
    src: 'image1SrcAll',
  },
  image_2: {
    x: 'image2XPositionAll',
    y: 'image2YPositionAll',
    zIndex: 'image2ZIndexAll',
    offsetXPercent: 'image2XPositionAll',
    offsetYPercent: 'image2YPositionAll',
    scale: 'image2ScaleAll',
    rotate: 'image2RotateAll',
    src: 'image2SrcAll',
  },
  headline: {
    text: 'headlineTextAll',
    zIndex: 'headlineZIndexAll',
  },
  subHeadline: {
    text: 'subHeadlineTextAll',
    zIndex: 'subHeadlineZIndexAll',
  },
  cta: {
    text: 'ctaTextAll',
    zIndex: 'ctaZIndexAll',
  },
  promo: {
    'discount.text': 'promoDiscountText',
    'code.text': 'promoCodeText',
  },
};

export const parseDateTime = (date, format) => {
  const d = typeof date === 'object' ? DateTime.fromJSDate(date) : DateTime.fromISO(date);
  if (format && typeof format === 'string') {
    return d.toFormat(format);
  }

  return d.toLocaleString(DateTime.DATETIME_SHORT);
};

export function createTokenRequest(type, req) {
  return createAsyncThunk(type, async (payload, { getState, rejectWithValue }) => {
    const token = getState()?.session.token;
    try {
      const res = await client.request({
        ...req,
        headers: { Authorization: `Bearer ${token}` },
      });
      return res.data;
    } catch (e) {
      return rejectWithValue(e.response.data);
    }
  });
}

export const calculateBannerImageOffset = (banner, props) => {
  const { target, direction, value } = props;
  if (!banner[target]) return {};
  const obj = { ...JSON.parse(JSON.stringify(banner[target])) };
  switch (direction) {
    case 'x':
      obj.offsetXPercent = value;
      obj.x = (banner.width / 100) * value - obj.width / 2;
      break;
    case 'y':
      obj.offsetYPercent = value;
      obj.y = (banner.height / 100) * value - obj.height / 2;
      break;
    default:
      return obj;
  }
  return obj;
};

export const getBannerLabel = (banner, childSize = 1) => {
  const resolution = `${banner.width * childSize}x${banner.height * childSize}`;
  let text;
  switch (resolution) {
    case '300x250':
      text = `${resolution} Desktop Checkout`;
      break;
    case '2560x100':
      text = `${resolution} Desktop PDP`;
      break;
    case '1908x236':
      text = `${resolution} Desktop search`;
      break;
    default:
      text =
        banner.width >= 954
          ? `${resolution} Desktop homepage and browser`
          : banner.width <= 728
          ? `${resolution} Mobile Web and App`
          : `${resolution} Unclassified banner`;
  }

  return text;
};
