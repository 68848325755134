import {
  createEntityAdapter,
  createSlice,
  createAsyncThunk,
  createListenerMiddleware,
  isAnyOf,
  createAction,
} from '@reduxjs/toolkit';
import client from '../utils/client';
import { calculateBannerImageOffset, layoutBannerKeyMapper } from '../utils';
import { DateTime } from 'luxon';
import { showSnackbar } from './global';
import deepmerge from 'deepmerge';

const projectAdapter = createEntityAdapter({
  sortComparer: (a, b) => b.updatedAt.localeCompare(a.updatedAt),
});

const initialState = projectAdapter.getInitialState({
  lastError: null,
  loading: 'idle' | 'pending' | 'succeeded' | 'failed',
  saveState: 'idle',
  activeProject: null,
  selectedBanner: null,
  banner: null,
  tableOrder: {
    field: 'updatedAt',
    sort: 'desc',
  },
  tableFilter: '',
  pagination: {
    page: 0,
    nextPage: null,
    prevPage: null,
    take: 10,
    skip: 0,
    totalCount: 0,
    resultCount: 0,
  },
});

export const execValidateProjectName = createAsyncThunk(
  'projects/validate',
  async (payload, { getState, rejectWithValue }) => {
    const token = getState()?.session.token;
    try {
      const res = await client.request({
        url: '/projects/validate',
        method: 'GET',
        params: { ...payload },
        headers: { Authorization: `Bearer ${token}` },
      });
      return res.data;
    } catch (e) {
      return rejectWithValue(e.response.data);
    }
  },
);

export const execGetRecentProjects = createAsyncThunk(
  'projects/recent',
  async (payload, { getState, rejectWithValue }) => {
    const token = getState()?.session.token;
    const pagination = getState()?.projects?.present?.pagination;
    const o = getState()?.projects?.present?.tableOrder;
    const filter = getState()?.projects?.present?.tableFilter;
    const orderBy = { [o.field]: o.sort };
    const params = {
      skip: pagination.page * pagination.take,
      take: pagination.take,
      orderBy,
    };

    if (filter && filter.length) {
      Object.assign(params, {
        where: {
          name: { contains: filter, mode: 'insensitive' },
        },
      });
    }

    try {
      const res = await client.request({
        url: '/projects/recent',
        method: 'GET',
        params,
        headers: { Authorization: `Bearer ${token}` },
      });
      return res.data;
    } catch (e) {
      return rejectWithValue(e.response.data);
    }
  },
);

export const execGetProject = createAsyncThunk('projects/detail', async (payload, { getState, rejectWithValue }) => {
  const token = getState()?.session.token;
  try {
    const res = await client.request({
      url: '/projects/'.concat(String(payload)),
      method: 'GET',
      headers: { Authorization: `Bearer ${token}` },
    });
    return res.data;
  } catch (e) {
    return rejectWithValue(e.response.data);
  }
});

export const execCloneProject = createAsyncThunk('projects/clone', async (payload, { getState, rejectWithValue }) => {
  const token = getState()?.session.token;
  try {
    const res = await client.request({
      url: `/projects/${payload}/duplicate`,
      method: 'POST',
      headers: { Authorization: `Bearer ${token}` },
    });
    return res.data;
  } catch (e) {
    return rejectWithValue(e.response.data);
  }
});

export const execInsertProject = createAsyncThunk('projects/create', async (payload, { getState, rejectWithValue }) => {
  const token = getState()?.session.token;
  try {
    const res = await client.request({
      url: '/projects',
      method: 'POST',
      data: { ...payload, name: payload?.name || 'Untitled Project' },
      headers: { Authorization: `Bearer ${token}` },
    });
    return res.data;
  } catch (e) {
    return rejectWithValue(e.response.data);
  }
});

export const execUpdateProject = createAsyncThunk('projects/update', async (payload, { getState, rejectWithValue }) => {
  const token = getState()?.session.token;
  const { id, ...rest } = payload;
  try {
    const res = await client.request({
      url: '/projects/'.concat(id),
      method: 'PUT',
      data: { ...rest },
      headers: { Authorization: `Bearer ${token}` },
    });
    return res.data;
  } catch (e) {
    return rejectWithValue(e.response.data);
  }
});

export const execDeleteProject = createAsyncThunk('projects/delete', async (payload, { getState }) => {
  const token = getState()?.session.token;
  const res = await client.request({
    url: '/projects/'.concat(String(payload)),
    method: 'DELETE',
    headers: { Authorization: `Bearer ${token}` },
  });
  return res.data;
});

export const execUploadMedia = createAsyncThunk('projects/media', async (payload, { getState }) => {
  const token = getState()?.session.token;
  const fd = new FormData();
  fd.append('file', payload?.file);
  fd.append('projectId', payload?.projectId);

  const res = await client.post('/media', payload, {
    headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'multipart/form-data' },
  });
  return res.data;
});

export const execCreateBanners = createAsyncThunk(
  'projects/create-banners',
  async (payload, { getState, dispatch }) => {
    const token = getState()?.session.token;

    const res = await client.post(
      `/projects/create-banners/${payload?.id}`,
      { note: payload?.note },
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    );
    dispatch(execGetProject(payload));
    return res.data;
  },
);

const projectsSlice = createSlice({
  name: 'projects',
  initialState,
  reducers: {
    filterTable(state, action) {
      state.tableFilter = action.payload;
    },
    createProject(state, action) {
      projectAdapter.addOne(state, action.payload);
    },
    deleteDraft(state, action) {
      projectAdapter.removeOne(state, action.payload);
    },
    updateBanner(state, action) {
      const { target, id, bannerId, changes } = action.payload;
      const project = state.entities[id];
      const { banners } = project;
      const updatedBanners = [...banners];
      for (const banner of updatedBanners) {
        if (banner.id === bannerId) {
          if (banner[target] !== undefined) {
            const newProps =
              typeof banner[target] === 'object' && typeof changes[target] === 'object'
                ? {
                    ...banner[target],
                    ...changes[target],
                  }
                : changes[target];
            banner[target] = newProps;

            if (state.banner && state.banner.id === bannerId) {
              state.banner[target] = newProps;
            }
          }
        }
      }
      projectAdapter.updateOne(state, {
        id: action.payload.id,
        changes: {
          banners: updatedBanners,
        },
      });
    },
    updateBanners(state, action) {
      const { target, id, changes } = action.payload;
      const project = state.entities[id];
      const { banners } = project;
      const updatedBanners = [...banners];

      for (const banner of updatedBanners) {
        if (banner[target] !== undefined) {
          banner[target] =
            typeof banner[target] === 'object' && typeof changes[target] === 'object'
              ? {
                  ...banner[target],
                  ...changes[target],
                }
              : changes[target];
        }
      }

      projectAdapter.updateOne(state, {
        id: action.payload.id,
        changes: {
          banners: updatedBanners,
        },
      });
    },
    updateBannerData(state, action) {
      const { id, target, key = null, value } = action.payload;
      const project = state.entities[id];
      const { banners } = project;
      const updatedBanners = [...banners];
      if (state.selectedBanner) {
        const idx = updatedBanners.findIndex((banner) => banner.id === state.selectedBanner);
        if (idx >= 0 && updatedBanners[idx][target]) {
          let data = value;
          if (key) {
            if (typeof updatedBanners[idx][target][key] === 'object' && typeof value === 'object') {
              data = { ...updatedBanners[idx][target][key], ...value };
            }
            updatedBanners[idx][target][key] = data;
          } else {
            if (typeof updatedBanners[idx][target] === 'object' && typeof value === 'object') {
              data = { ...updatedBanners[idx][target], ...value };
            }
            updatedBanners[idx][target] = data;
          }
          state.banner = updatedBanners[idx];
        }
        projectAdapter.updateOne(state, { id, changes: { banners: updatedBanners } });
      } else {
        for (const bb of updatedBanners) {
          if (!bb[target]) {
            continue;
          }

          if (key && !bb[target][key]) {
            bb[target][key] = value;
          }

          if (key) {
            bb[target][key] = value;
          } else {
            bb[target] = value;
          }

          /*console.log(updatedBanners[k][target], target, key, value)
          if (!updateBanners[k].hasOwnProperty(target)) {
            continue;
          }
          if (key) {
            updatedBanners[k][target][key] = value;
          } else {
            updatedBanners[k][target] = value;
          }*/
        }

        const templateKey = key ? layoutBannerKeyMapper[target][key] : layoutBannerKeyMapper[target];
        projectAdapter.updateOne(state, {
          id,
          changes: { banners: updatedBanners, template: { ...project.template, [templateKey]: value } },
        });
      }
    },
    updatePromoData(state, action) {
      const { id, target, value } = action.payload;
      const [subPath, keyOne, keyTwo] = target.split('.');
      const project = state.entities[id];
      const { banners } = project;
      const updatedBanners = [...banners];
      if (state.selectedBanner) {
        const idx = updatedBanners.findIndex((banner) => banner.id === state.selectedBanner);
        if (idx >= 0 && updatedBanners[idx].promo[subPath]) {
          let data = value;
          if (keyTwo) {
            if (typeof updatedBanners[idx].promo[subPath][keyOne][keyTwo] === 'object' && typeof value === 'object') {
              data = { ...updatedBanners[idx].promo[subPath][keyOne][keyTwo], ...value };
            }
            updatedBanners[idx].promo[subPath][keyOne][keyTwo] = data;
          } else {
            if (typeof updatedBanners[idx].promo[subPath][keyOne] === 'object' && typeof value === 'object') {
              data = { ...updatedBanners[idx].promo[subPath][keyOne], ...value };
            }
            updatedBanners[idx].promo[subPath][keyOne] = data;
          }
          state.banner = updatedBanners[idx];
        }
        projectAdapter.updateOne(state, { id, changes: { banners: updatedBanners } });
      } else {
        for (const k in updatedBanners) {
          if (keyTwo) {
            updatedBanners[k].promo[subPath][keyOne][keyTwo] = value;
          } else if (keyOne) {
            updatedBanners[k].promo[subPath][keyOne] = value;
          } else if (!subPath) {
            updatedBanners[k].promo[target] = value;
          }
        }

        const templateKey = layoutBannerKeyMapper.promo[target];
        projectAdapter.updateOne(state, {
          id,
          changes: { banners: updatedBanners, template: { ...project.template, [templateKey]: value } },
        });
      }
    },
    updateBannerImagePosition(state, action) {
      const { id, target, direction, value } = action.payload;
      const project = state.entities[id];
      const { banners } = project;
      const updatedBanners = [...banners];
      if (state.selectedBanner) {
        const idx = updatedBanners.findIndex((banner) => banner.id === state.selectedBanner);
        if (idx >= 0 && updatedBanners[idx][target]) {
          const d = calculateBannerImageOffset(updatedBanners[idx], { target, direction, value });
          updatedBanners[idx][target] = { ...updatedBanners[idx][target], ...d };
          state.banner = updatedBanners[idx];
        }
        projectAdapter.updateOne(state, { id, changes: { banners: updatedBanners } });
      } else {
        for (const k in updatedBanners) {
          if (updatedBanners[k][target]) {
            const d = calculateBannerImageOffset(updatedBanners[k], { target, direction, value });
            updatedBanners[k][target] = { ...updatedBanners[k][target], ...d };
          }
        }

        projectAdapter.updateOne(state, {
          id,
          changes: {
            banners: updatedBanners,
            template: { ...project.template, [layoutBannerKeyMapper[target][direction]]: value },
          },
        });
      }
    },
    updateBannerImageData(state, action) {
      const { id, target, value } = action.payload;
      const project = state.entities[id];
      const { banners } = project;
      const updatedBanners = [...banners];
      if (state.selectedBanner) {
        const idx = updatedBanners.findIndex((banner) => banner.id === state.selectedBanner);
        if (idx >= 0 && updatedBanners[idx][target]) {
          const current = updatedBanners[idx][target];
          updatedBanners[idx][target] = {
            ...current,
            ...value,
            height: Math.floor(current.width * value.aspectRatio),
            width: current.width,
          };
          state.banner = updatedBanners[idx];
        }
        projectAdapter.updateOne(state, { id, changes: { draft: false, banners: updatedBanners } });
      } else {
        for (const k in updatedBanners) {
          if (updatedBanners[k][target]) {
            const current = updatedBanners[k][target];
            updatedBanners[k][target] = {
              ...current,
              ...value,
              height: Math.floor(current.width * value.aspectRatio),
              width: current.width,
            };
          }
        }

        projectAdapter.updateOne(state, {
          id,
          changes: {
            draft: false,
            banners: updatedBanners,
            template: { ...project.template, [layoutBannerKeyMapper[target].src]: value.src },
          },
        });
      }
    },
    updateColors(state, action) {
      const { id, target, value } = action.payload;
      const [key, subKey] = target.split('.');
      const project = state.entities[id];
      const { banners } = project;
      const updatedBanners = [...banners];
      if (state.selectedBanner) {
        const idx = updatedBanners.findIndex((banner) => banner.id === state.selectedBanner);
        if (idx >= 0) {
          if (subKey) {
            updatedBanners[idx].colors[key][subKey] = value;
          } else {
            updatedBanners[idx].colors[key] = value;
          }
          state.banner = updatedBanners[idx];
        }
        projectAdapter.updateOne(state, { id, changes: { banners: updatedBanners } });
      } else {
        for (const k in updatedBanners) {
          if (subKey) {
            updatedBanners[k].colors[key][subKey] = value;
          } else {
            updatedBanners[k].colors[key] = value;
          }
        }

        const colors = subKey ? { [key]: { [subKey]: value } } : { [key]: value };
        projectAdapter.updateOne(state, {
          id,
          changes: {
            banners: updatedBanners,
            template: { ...project.template, colors: deepmerge(project.template.colors, colors) },
          },
        });
      }
    },
    updateTemplate(state, action) {
      const { id, changes } = action.payload;
      const { template } = state.entities[id];
      projectAdapter.updateOne(state, { id, changes: { template: { ...template, ...changes } } });
    },
    setProjectLayout(state, action) {
      state.selectedBanner = null;
      state.banner = null;
      projectAdapter.updateOne(state, {
        id: action.payload.id,
        changes: { ...action.payload.changes, updatedAt: DateTime.utc().toISO() },
      });
    },
    setSelectedBanner(state, action) {
      state.selectedBanner = action.payload?.bannerId;
      if (!state.selectedBanner) {
        state.banner = null;
      } else {
        const { banners } = state.entities[action.payload.projectId];
        state.banner = banners.find((b) => b.id === action.payload.bannerId);
      }
    },
    resetSelectedBanner(state, action) {
      state.banner = action.payload.changes.banners.find((b) => b.id === state.selectedBanner);
      projectAdapter.updateOne(state, action.payload);
    },
    resetProject(state, action) {
      projectAdapter.updateOne(state, action.payload);
    },
    setActiveProject(state, action) {
      state.activeProject = action.payload;
    },
    clearActiveProject(state) {
      state.activeProject = null;
      state.selectedBanner = null;
      state.banner = null;
      state.loading = 'idle';
      state.saveState = 'idle';
      state.lastError = null;
    },
    updatePagination(state, action) {
      state.pagination = { ...state.pagination, ...action.payload };
    },
    updateOrder(state, action) {
      state.tableOrder = action.payload || { ...initialState.tableOrder };
    },
    updateProject(state, action) {
      const { id, key, value } = action.payload;
      if (!key && typeof value === 'object') {
        const currentEntity = state.entities[id];
        state.entities[id] = { ...currentEntity, ...value }; // consider using object deep merge
      } else {
        state.entities[id][key] = value;
      }
    },
    setSaveState(state, action) {
      state.saveState = action.payload;
    },
    clearState(state) {
      projectAdapter.removeAll(state);
    },
  },
  extraReducers: (builder) => {
    /// RECENT PROJECTS
    builder
      .addCase(execGetRecentProjects.pending, (state, action) => {
        state.loading = 'pending';
      })
      .addCase(execGetRecentProjects.fulfilled, (state, action) => {
        state.loading = 'succeeded';
        state.pagination = action.payload.pagination;
        projectAdapter.setAll(state, action.payload.docs);
      })
      .addCase(execGetRecentProjects.rejected, (state, action) => {
        state.loading = 'failed';
        state.lastError = action.payload;
      });

    // GET ONE PROJECT
    builder
      .addCase(execGetProject.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(execGetProject.fulfilled, (state, action) => {
        state.loading = 'succeeded';
        projectAdapter.upsertOne(state, action.payload);
      })
      .addCase(execGetProject.rejected, (state, action) => {
        state.lastError = action.payload;
      });

    /// CREATE PROJECT
    builder
      .addCase(execInsertProject.fulfilled, (state, action) => {
        projectAdapter.updateOne(state, {
          id: action.payload.id,
          changes: { ...action.payload, saved: true, draft: false },
        });
      })
      .addCase(execInsertProject.rejected, (state, action) => {
        state.saveState = 'rejected';
        state.lastError = action.payload;
      });

    // Update
    builder
      .addCase(execUpdateProject.fulfilled, (state, action) => {
        projectAdapter.updateOne(state, { id: action.payload.id, changes: { ...action.payload } });
      })
      .addCase(execUpdateProject.rejected, (state, action) => {
        state.lastError = action.payload;
      });

    /// DUPLICATE /CLONE PROJECT
    builder
      .addCase(execCloneProject.fulfilled, (state, action) => {
        projectAdapter.addOne(state, action.payload);
      })
      .addCase(execCloneProject.rejected, (state, action) => {
        state.lastError = action.payload;
      });

    /// DELETE PROJECT
    builder
      .addCase(execDeleteProject.fulfilled, (state, action) => {
        projectAdapter.removeOne(state, action.payload.id);
      })
      .addCase(execDeleteProject.rejected, (state, action) => {
        state.lastError = action.payload;
      });
  },
});

export const {
  updateProject,
  createProject,
  deleteDraft,
  setProjectLayout,
  updateBanner,
  updateBanners,
  setSelectedBanner,
  resetProject,
  resetSelectedBanner,
  updateTemplate,
  updateBannerData,
  updatePromoData,
  updateBannerImagePosition,
  updateBannerImageData,
  setActiveProject,
  clearActiveProject,
  setSaveState,
  updatePagination,
  updateOrder,
  updateColors,
  filterTable,
  clearState: clearProjectState,
} = projectsSlice.actions;

export default projectsSlice.reducer;

export const {
  selectAll: selectProjects,
  selectById: selectProject,
  selectTotal: selectProjectTotal,
  selectIds: selectProjectIds,
} = projectAdapter.getSelectors((state) => state?.projects?.present ?? initialState);

export const selectPastProjects = (state, id) => {
  const p = state?.projects.find((p) => p.entities && p.ids.length);
  return (p && p.entities && Object.values(p.entities)) || [];
};

export const getActiveProject = (state) => {
  return selectProject(state, state.projects?.present.activeProject);
};

export const selectActiveBanner = (state, id) => {
  if (!state.projects?.present?.selectedBanner) return null;
  const project = selectProject(state, id);
  return project.banners.find((banner) => banner.id === state.projects?.present?.selectedBanner);
};

export const getPagination = (state) => {
  return state.projects?.present?.pagination || initialState.pagination;
};

export const getTableOrder = (state) => {
  return state.projects?.present?.tableOrder || initialState.tableOrder;
};

export const getTableFilter = (state) => {
  return state.projects?.present?.tableFilter || initialState.tableFilter;
};

export const saveProject = createAction('project/saveProject');

const projectListenerMiddleware = createListenerMiddleware();

projectListenerMiddleware.startListening({
  matcher: isAnyOf(
    updateProject,
    updateBanners,
    updateBanner,
    updateBannerImageData,
    updateBannerImagePosition,
    updatePromoData,
    updateTemplate,
    updateBannerData,
    resetProject,
    resetSelectedBanner,
    setProjectLayout,
  ),
  effect: async (action, listenerApi) => {
    listenerApi.cancelActiveListeners();
    await listenerApi.delay(500);
    const state = listenerApi.getState();
    const project = selectProject(state, action.payload.id || state.projects?.present?.activeProject);
    if (action.type === 'projects/setProjectLayout' && (project.draft || !project.saved)) {
      await listenerApi.dispatch(setSaveState('idle'));
      return false;
    }

    if (project) {
      return listenerApi.dispatch(saveProject(action?.payload));
    }
  },
});

projectListenerMiddleware.startListening({
  predicate: (action, currentState, previousState) => {
    const { type } = action;
    return ['@@redux-undo/REDO', '@@redux-undo/UNDO'].includes(type);
  },
  effect: async (action, listenerApi) => {
    listenerApi.cancelActiveListeners();
    await listenerApi.dispatch(setSaveState('pending'));
    await listenerApi.delay(500);

    const state = listenerApi.getState();
    const project = selectProject(state, state.projects?.present?.activeProject);
    const { id, name, template, layoutId, banners } = project;
    await client.request({
      url: '/projects/'.concat(id),
      method: 'PUT',
      data: { id, name, template, layoutId, banners },
      headers: { Authorization: `Bearer ${state.session.token}` },
    });
  },
});

projectListenerMiddleware.startListening({
  matcher: isAnyOf(updatePagination, updateOrder, filterTable),
  effect: async (action, listenerApi) => {
    listenerApi.cancelActiveListeners();
    await listenerApi.delay(10);

    await listenerApi.dispatch(execGetRecentProjects());
  },
});

projectListenerMiddleware.startListening({
  predicate: (action, currentState, previousState) => {
    const { type } = action;
    return ['projects/create/rejected', 'projects/update/rejected', 'projects/delete/rejected'].includes(type);
  },
  effect: async (action, listenerApi) => {
    listenerApi.cancelActiveListeners();
    await listenerApi.delay(10);

    await listenerApi.dispatch(
      showSnackbar({ message: action.payload.message, severity: 'error', autoHideDuration: 4000 }),
    );
    await listenerApi.dispatch(setSaveState('error'));
    await listenerApi.delay(5000);
    await listenerApi.dispatch(setSaveState('idle'));
  },
});

projectListenerMiddleware.startListening({
  predicate: (action, currentState, previousState) => {
    const { type } = action;
    return ['projects/create/fulfilled', 'projects/update/fulfilled'].includes(type);
  },
  effect: async (action, listenerApi) => {
    listenerApi.cancelActiveListeners();
    await listenerApi.delay(500);

    await listenerApi.dispatch(setSaveState('idle'));
  },
});

projectListenerMiddleware.startListening({
  actionCreator: saveProject,
  async effect(action, listenerApi) {
    await listenerApi.dispatch(setSaveState('pending'));
    listenerApi.cancelActiveListeners();

    const state = listenerApi.getState();
    const projectId = action?.payload?.id;

    const activeProject = projectId || state.projects.present?.activeProject;
    if (activeProject) {
      await listenerApi.delay(500);
      const project = selectProject(state, activeProject);
      const { id, name, template, layoutId, banners } = project;
      listenerApi.unsubscribe();
      await listenerApi.dispatch(
        project.draft
          ? execInsertProject({ id, name, template, layoutId, banners })
          : execUpdateProject({ id, name, template, layoutId, banners }),
      );
      listenerApi.subscribe();
    }

    return Promise.resolve();
  },
});

export { projectListenerMiddleware };
