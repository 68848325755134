import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';
import globalReducer from './global';
import sessionReducer, { performLogout } from './session';
import projectReducer, { clearProjectState, projectListenerMiddleware } from './projects';
import storage from 'redux-persist/lib/storage';
import layoutReducer, { clearLayoutState } from './layouts';
import settingReducer from './settings';
import undoable, { ActionCreators, excludeAction, includeAction } from 'redux-undo';

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
};

const rootReducer = combineReducers({
  global: globalReducer,
  projects: undoable(projectReducer, { filter: includeAction(['projects/update/fulfilled']), limit: 10 }),
  session: sessionReducer,
  layouts: layoutReducer,
  settings: settingReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const authMiddleware = (store) => (next) => (action) => {
  if (action?.error && action?.payload?.statusCode) {
    if (action.payload.statusCode === 401 && store.getState()?.session?.loggedIn) {
      return store.dispatch(performLogout());
    }
  }
  return next(action);
};

const clearStoreMiddleware = (store) => (next) => async (action) => {
  if (action.type === 'session/logout/fulfilled') {
    await store.dispatch(clearProjectState())
    await store.dispatch(clearLayoutState())
    await store.dispatch(ActionCreators.clearHistory());
  }

  return next(action);
}

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false /* {
        ignoredActionPaths: ['project/updateBannerData'],
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },*/,
    })
      .concat(authMiddleware)
      .concat(projectListenerMiddleware.middleware)
      .concat(clearStoreMiddleware),
});

export default store;
