import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import { createRoot } from 'react-dom/client';

import App from './App';
import store from './store';

import 'normalize.css';
import './styles/styles.scss';

const persistingState = persistStore(store);
const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistingState}>
        <App />
      </PersistGate>
    </Provider>
  </React.StrictMode>,
);
