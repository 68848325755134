import React from 'react';
import { GridToolbarQuickFilter } from '@mui/x-data-grid';

export default function QuickFilter() {
  return (
    <div
      className="tableHeader"
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '40px 0 20px 0',
      }}
    >
      <h2>Recent Designs</h2>
      <GridToolbarQuickFilter style={{ maxWidth: 500, width: '50%' }}></GridToolbarQuickFilter>
    </div>
  );
}
